import React from "react"
import { graphql, Link } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import Layout from "../../components/layout/layout"
import PageTransition from "../../components/transition/page"
import PageHeader from "../../components/layout/page-header"
import SEO from "../../components/seo/seo"
import SliderCarousel from "../../components/carousel/slider"
import Thumbnail from "../../components/thumbnail/thumbnail"
import ThumbnailGroup from "../../components/thumbnail/thumbnail-group"

class SurfingPage extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }
  animate() {
    this.pageTransition.current.play()
  }
  render() {
    const { path, data } = this.props
    const locale = "en-AU"
    const { slider, featured, articles } = data
    let items = []
    articles.articles.forEach((item, index) => {
      items.push(item.article)
    })
    return (
      <Layout locale={locale} theme="light" path={path} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO
          title={data.seo.title}
          description={data.seo.description}
          keywords={data.seo.keywords && data.seo.keywords.keywords}
          image={data.seo.image.file.url}
        />
        <PageHeader bottom={true}>
          <SliderCarousel slides={slider.slides} locale={locale} />
        </PageHeader>
        <section>
          <div className={"container mx-auto px-4"}>
            <div className={"flex flex-wrap"}>
              <div className={"mx-auto w-full md:w-1/2 px-4"}>
                <Link to={`/${locale}/articles/${featured.articles[0].article.slug}`}>
                  <Thumbnail
                    title={featured.articles[0].article.title}
                    image={featured.articles[0].article.image.file.url}
                    description={featured.articles[0].article.short}
                    height={340}
                  />
                </Link>
              </div>
              <div className={"mx-auto w-full md:w-1/2 px-4"}>
                <Link to={`/${locale}/articles/${featured.articles[1].article.slug}`}>
                  <Thumbnail
                    title={featured.articles[1].article.title}
                    image={featured.articles[1].article.image.file.url}
                    description={featured.articles[1].article.short}
                    height={340}
                  />
                </Link>
              </div>
            </div>
            <div className={"flex flex-wrap"}>
              <div className={"mx-auto w-full md:w-1/2 px-4"}>
                <Link to={`/${locale}/articles/${featured.articles[2].article.slug}`}>
                  <Thumbnail
                    title={featured.articles[2].article.title}
                    image={featured.articles[2].article.image.file.url}
                    description={featured.articles[2].article.short}
                    height={340}
                  />
                </Link>
              </div>
              <div className={"mx-auto w-full md:w-1/2 px-4"}>
                <Link to={`/${locale}/articles/${featured.articles[3].article.slug}`}>
                  <Thumbnail
                    title={featured.articles[3].article.title}
                    image={featured.articles[3].article.image.file.url}
                    description={featured.articles[3].article.short}
                    height={340}
                  />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <ThumbnailGroup items={items} href={`/${locale}/articles/`} />
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
      
    )
  }
}

export const query = graphql`
  query SurfingPageQuery {
    seo: contentfulSeo(slug: {eq: "surfing"}) {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    slider: contentfulSlider(slug: { eq: "surfing" }, node_locale: { eq: "en-AU" }) {
      slides {
        ... on ContentfulSlide {
          title
          short
          image {
            file {
              url
            }
          }
          link
          cta
        }
      }
    }
    featured: allContentfulArticle(limit: 4, filter: { visible: { eq: true }, node_locale: { eq: "en-AU" }, category: { slug: { eq: "surfing" } } }) {
      articles: edges {
        article: node {
          title
          short
          slug
          image {
            file {
              url
            }
          }
        }
      }
    }
    articles: allContentfulArticle(
      limit: 50
      filter: { visible: { eq: true }, node_locale: { eq: "en-AU" }, category: { slug: { eq: "surfing" } } }
    ) {
      articles: edges {
        article: node {
          title
          short
          slug
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default SurfingPage
